import React from 'react';
import {observer} from 'mobx-react';
import BaseCategory from './baseCategory';
import {action, observable} from 'mobx';
import Loop from '../posts/loop';
import {PreloaderWithWrapper} from '../modules/ui';

@observer
class Skate extends BaseCategory {
   
    @observable catInfo = null;

    constructor(props, context){
        super(props, context);
        const domain = this.context.DomainStore.DOMAIN;
        this.endpoint = `${domain}/wp-json/wp/v2/posts?categories=3&per_page=10&_embed=1`;

        this.getCategoryInfo = this.getCategoryInfo.bind(this);
    }

    async componentDidMount(){
        await super.componentDidMount();
        this.getCategoryInfo();
    }

    @action
    getCategoryInfo(){
        if(this.hasLoaded){
            const { UIStore } = this.context;
            const cat = UIStore.CATEGORY_DATA.find(cat => cat.id === this.props.id);
            this.catInfo = cat.description;
        }
    }

    render(){
        return (
            <section className="page page--loop">
                {!this.hasLoaded &&
                    <PreloaderWithWrapper />
                }
                {this.hasLoaded &&
                    <div className="animated-wrapper loop__wrapper">
                        {this.filterDataWrapper()}
                        <Loop 
                            items={this.filteredContent} 
                            endpoint={this.endpoint} 
                            limit={this.limit}
                            additionalData={this.catInfo}
                        />
                    </div>
                }
            </section>
        )
    }

}


export default Skate;