import React, { Component } from 'react';
import {observer} from 'mobx-react';
import LoopPost from './loopPost';
import Slider from "react-slick";
import { action, observable } from 'mobx';
import { fetchData } from '../../utils/common';


@observer
class Loop extends Component {

    @observable count = 1;

    constructor(props){
        super(props);
        this.getExtraItems = this.getExtraItems.bind(this);
    }

    /**
     * Once the user starts to scroll manually add new items via AJAX
     * @param {*} current 
     * @param {*} next 
     */
    @action
    async getExtraItems(current, next){
        const {limit, endpoint, items} = this.props;
        const limitPosts = (!!limit && limit > 10) ? limit : 40;        
        const isScrollingF = (next > current && current > 0);
        const total = items.length;

        if(total < limitPosts && (total - next <=5) && isScrollingF){
            const response = await fetchData(`${endpoint}&offset=${this.count * 10}`);
            this.count++;
        
            if(response){
                this.props.items.push(...response);
            }

        }
    }

    getCorrectImageSize(index){
        const imageArr = this.props.items[index]._embedded["wp:featuredmedia"][0];
        let ml, md = null;

        if(imageArr){
            ml = this.props.items[index]._embedded["wp:featuredmedia"][0]?.media_details?.sizes?.medium_large?.source_url;
            md = this.props.items[index]._embedded["wp:featuredmedia"][0]?.media_details?.sizes?.medium_large?.source_url;
        }
        if(!ml && !md){
            return imageArr.source_url
        }
        else {
            return (ml) ? md : md;
        }
    }

    render(){
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            centerMode: true,
            variableWidth: true,
            slidesToScroll: 1,
            beforeChange: async (current, next) => await this.getExtraItems(current, next),
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }
                },
                {
                  breakpoint: 500,
                  settings: {
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
        }

        const loopList = () => (
           this.props.items.map((item, index) => (
                <LoopPost 
                    title={item.title.rendered}
                    excerpt={item.excerpt.rendered}
                    thumb={this.getCorrectImageSize(index)}
                    link={item.slug}
                    key={index}
                /> 
            ))
        )

        return (
            <section className="loop loop--posts">
                <main className="posts">
                    <Slider {...settings}>
                        {this.props.additionalData &&
                        <CategoryInfo description={this.props.additionalData} />
                        }
                        {loopList()}
                    </Slider>
                </main>
            </section>
        )
    }
}


/**
 * Display the category's description - optiona;
 * @param {*} props 
 */
const CategoryInfo = (props) => {
    const createMarkup = () => ({__html: props.description});

    return(
        <article className="loop loop__post loop__post--txt loop__post--desc">
            <div dangerouslySetInnerHTML={createMarkup()} />
        </article>
    )
}

export default Loop;