import React, { Component } from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import { parseHtmlEnteties } from '../../utils/common';

@observer
class LoopPost extends Component {

    render(){
        const createMarkup = () => ({__html: this.props.excerpt});
        return (
            <article className="loop loop__post">
                <Link to={`/${this.props.link}`}>
                    <figure className="post__thumb">
                        <img src={this.props.thumb} alt={this.props.title} />
                    </figure>
                    <h2 className="post__title">
                        {parseHtmlEnteties(this.props.title)}
                    </h2>
                    {this.props.excerpt && <section className="post__excerpt" dangerouslySetInnerHTML={createMarkup()} />}
                </Link>
            </article>
        )
    }
}


export default LoopPost;