import React, { Component } from 'react';
import { fetchData } from '../utils/common';
import {runInAction, observable} from 'mobx';
import {observer} from 'mobx-react';
import { Store } from '../stores/storesContext';

@observer
class BaseComponent extends Component {
    
    @observable hasLoaded = false;
    content = null;
    endpoint = null;
    oldHead = null;

    async componentDidMount(){
        if(this.endpoint){
            await this.getContent(this.endpoint);
        }
    }

    componentWillUnmount(){
        this.clearHeaderDataAfterUnmounting();
    }

    /**
     * Fetch the remote data
     * @param {*} endpoint 
     */
    async getContent(endpoint){
        let data = null;
        data = await fetchData(endpoint);

        if(data){
            this.content = data[0];
            runInAction(() => this.hasLoaded = true );
            this.contructHeadData();
        }  
    }

    clearHeaderDataAfterUnmounting(){
        if(this.oldHead){
            const head = document.getElementsByTagName('head')[0];
            const defaultHeader = this.oldHead;
            const doc = document.getElementsByTagName('html')[0];
            doc.replaceChild(defaultHeader, head);
            this.oldHead = null;
        }
    }

    contructHeadData(){
        if(this.hasLoaded && this.content){
            const headData = (this.content.yoast_head) ? 
                this.content.yoast_head : this.context.DomainStore.getDefaultMetaData().content;
            
            const head = document.getElementsByTagName('head')[0];
            this.oldHead = head.cloneNode(true);
            head.insertAdjacentHTML('beforeend', headData);
        }
    }

    render(){
        return <div />
    }
}

BaseComponent.contextType = Store;
export default BaseComponent;