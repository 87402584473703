import React from 'react';
import {action} from 'mobx';
import {observer} from 'mobx-react';
import BaseCategory from './baseCategory';


@observer
class Art extends BaseCategory {
    
    TAGS = {
        "Interior" : 4 ,
        "Exterior": 5,
        "Painting": 71
    }
   
    constructor(props, context){
        super(props, context);
        const domain = this.context.DomainStore.DOMAIN;
        this.endpoint = `${domain}/wp-json/wp/v2/posts?categories=69&per_page=10&_embed=1`;
        this.filterData = this.filterData.bind(this);
    }

    /**
     * Content filtering
     * @param {} key 
     */
    @action
    filterData(key){
        if(key !== 0){
            this.filteredContent.replace(this.content.slice().filter(item => item.tags?.includes(key)));
        }
        else {
            this.filteredContent.replace(this.content);
        }
    }

    filterDataWrapper(){
        // return (
        //     <nav className="posts__filters">
        //         <button onClick={() => this.filterData(0)}>All</button>
        //         <button onClick={() => this.filterData(this.TAGS.Interior)}>Interior</button>
        //         <button onClick={() => this.filterData(this.TAGS.Exterior)}>Exterior</button>    
        //     </nav>
        // );

        return null;
    }

}


export default Art;