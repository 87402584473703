import React from 'react';
import logo from '../../assets/img/logo.png';
import {Link} from 'react-router-dom';

export const Preloader = () => {
    return (
        <div className="lds-ripple">
            <div></div>
            <div></div>
        </div>
    );
}

export const PreloaderWithWrapper = () => {
    return (
        <div className="flex flex-abs-center posts">
            <div className="lds-ripple">
                <div></div>
                <div></div>
            </div>
        </div>
    );
}



export const Logo = () => {
    return (
        <div className="logo">
            <Link to="/">
                <img src={logo} alt="logo" />
            </Link>
        </div>
    );
}