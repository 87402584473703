import React, { Component } from 'react';
import Nav from './nav';
import { Logo } from './modules/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
 
export default class Header extends Component {

    render(){
        return ( 
            <div className='flex flex-center global__header'>                
                <Logo />
                <Nav />
                <a className="instalink" href="https://www.instagram.com/billy_gee/" target="_blank" rel="noopener noreferrer">
                    Instagram <span><FontAwesomeIcon icon={faInstagram} /></span>
                </a>
            </div>
        )
    }
}