import React from 'react';
import {observer} from 'mobx-react';
import BaseComponent from '../baseComponent';
import {Preloader} from '../modules/ui';

@observer
class Bio extends BaseComponent {

    constructor(props){
        super(props);
        this.endpoint = 'https://billygee.gr/wp-json/wp/v2/pages/?slug=bio';
    }

    populateData(){
        if(this.hasLoaded){
            const { title, content } = this.content;
            const createMarkup = () => ({__html: content.rendered});

            return (
                <React.Fragment>
                    <h1>{title.rendered}</h1>
                    <div  dangerouslySetInnerHTML={createMarkup()} />
                </React.Fragment>
            )
        }
    }

    render(){
        return (
            <article className="page page--bio">
                <main className="single__content">
                    {!this.hasLoaded && 
                        <Preloader />
                    }
                    {this.hasLoaded &&
                        <div className="animated-wrapper">
                            {this.populateData()}
                        </div>
                    }
                </main>
            </article>
        )
    }
}


export default Bio;