import React, {Component} from 'react';
import { Route, Routes } from "react-router";
import { Home, Page, Bio, Post, Art, Skate } from '../';
import { Store } from '../../stores/storesContext';

class Wrapper extends Component {

  render(){
    return (      
        <div className='animated-wrapper main-wrapper'>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/new" element={<Home />} />
                <Route
                  exact
                  path='/category/skate'
                  element={<Skate id={3} />} 
                />
                <Route
                  exact
                  path='/category/art'
                  element={<Art id={69} />} 
                />

                {/* <Route path="/category/:slug" component={BaseCategory} /> */}
                <Route path="/bio" element={<Bio />} />
                <Route 
                  path="/contact" 
                  element={<Page slug="contact" />}
                />
                <Route path="/:slug" element={<Post />} />
            </Routes>
        </div>
    );
  }
}


Wrapper.contextType = Store;
export default Wrapper;

