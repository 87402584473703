import React from 'react';

export default function Footer() {
    return (
        <div className="global__footer">
            <a href="https://www.instagram.com/billy_gee/" target="_blank" rel="noopener noreferrer">
                Instagram
            </a>
        </div>
    )
}