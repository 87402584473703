import React from 'react';
import './assets/scss/style.scss';
import Footer from './components/footer';
import Header from './components/header';
import Wrapper from './components/wrapper/wrapper';
import { useLocation } from 'react-router-dom';
import useGoogleAnalytics from './utils/ga';

export default function App(){
  const location = useLocation();
  const wrapperClass = location.pathname === '/' 
      ? 'App is-home'
      : 'App';
  
  // GA Hook
  useGoogleAnalytics();

  return (      
      <div className={wrapperClass}>
        <Header />
        <Wrapper />
        <Footer />
      </div>
  );
}
