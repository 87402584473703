import React from 'react';
import {observer} from 'mobx-react';
import BaseComponent from '../baseComponent';
import { parseHtmlEnteties } from '../../utils/common';
import { useParams,  } from "react-router-dom";
import { createBrowserHistory } from "history";

/**
 * React Router 6 hooks
 * only work with functions and not classes
 * 
 * @param {*} Component 
 * @returns 
 */
function withParams(Component) {
    const customHistory = createBrowserHistory();
    return props => <Post 
        {...props} params={useParams()} 
        history={customHistory} 
    />;
}

@observer
class Post extends BaseComponent {

    constructor(props, context){
        super(props, context);
        const domain = this.context.DomainStore.DOMAIN;
        this.endpoint = `${domain}/wp-json/wp/v2/posts/?slug=${this.props.params.slug}&_embed=1`;
        this.goBack = this.goBack.bind(this);
    }

    /**
     * get the appropriate size for the images
     * @param {*} source 
     */
    getPostImage(source){
        return (!source.large) 
            ? source.full?.source_url 
            : source.large?.source_url;
    }

    goBack(e){
        e.preventDefault();
        this.props.history.back();
    }

    render(){
        const createMarkup = () => ({__html: this.content.content.rendered});
        return (
            <>
            {this.hasLoaded && this.content &&
                <article className="flex flex-wrap single">
                    <header className="text-center single__header">
                        {this.content?._embedded["wp:featuredmedia"] && this.content._embedded["wp:featuredmedia"][0]?.media_details?.sizes &&
                        <>
                        <figure className="flex flex-abs-center single__image">
                            <img src={this.getPostImage(this.content._embedded["wp:featuredmedia"][0].media_details.sizes)} 
                                alt={this.content.title}
                            />
                        </figure>
                        <div className="single__scroll">
                            <div className="bounce--down arrow down"></div>
                            <span>SCROLL</span>
                        </div>
                        </>
                        }
                    </header>
                    <div className="single__content">
                        <h1 className="text-center single__title">
                            {parseHtmlEnteties(this.content.title.rendered)}
                        </h1>
                        <main className="single__wrapper" dangerouslySetInnerHTML={createMarkup()} />

                        <p className="single__cta"><a href="/" onClick={(e) => this.goBack(e)}>Go Back</a></p>
                    </div>
                </article>
            }
            {this.hasLoaded && !this.content &&
                <div className="single__content">
                    <h1 className="text-center single__title">
                            Something went wrong
                    </h1>
                    <main className="text-center single__wrapper">
                        Oops! Page not found
                    </main>
                </div>
            }
            </>
        )
    }
}

export default withParams(Post);