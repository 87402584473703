import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { fetchData } from '../utils/common';
import {runInAction, observable, action} from 'mobx';
import {observer} from 'mobx-react';
import classNames from 'classnames';

@observer
class Nav extends Component {
   
    @observable hasLoaded = false;
    @observable isMenuOpen = false;

    content = null;

    constructor(props){
        super(props);
        this.handleMenuClick = this.handleMenuClick.bind(this);
    }

    async componentDidMount(){
        await this.getContent();
    }

    async getContent(){
        let data = null;
        data = await fetchData('https://billygee.gr/wp-json/menus/v1/menus/main-menu');

        if(data){
            this.content = data;
            runInAction(() => this.hasLoaded = true );
        }
            
    }

    @action
    handleMenuClick(e){
        e.preventDefault();
        this.isMenuOpen = !this.isMenuOpen;
    }

    @action
    closeMenu(){
        this.isMenuOpen = !this.isMenuOpen;
    }

    hanleLinkString(string){
        const s = string.replace("https://billygee.gr", "");
        const s2 = s.replace("http://billygee.gr", "");

        return s2;
    }

    populateData(){
        if(this.hasLoaded){
            return this.content.items.map(item => (
                <React.Fragment key={item.ID}>
                    <li>
                        <Link
                            onClick={() => this.closeMenu()} 
                            to={this.hanleLinkString(item.url)}
                        >
                            {item.title}
                        </Link>
                    </li>
                </React.Fragment>
            ));
        }
    }

    render(){
        const menuClass = classNames('main-nav',  {
            'main-nav--open': this.isMenuOpen,
        }); 

        const toggleClass = classNames('menu__toggle',  {
            'menu__toggle--open': this.isMenuOpen,
        }); 

        return (
            <>
            <span className={toggleClass} onClick={(e) => this.handleMenuClick(e)}>
                <span></span>
                <span></span>
                <span></span>
            </span>
            <nav className={menuClass}>
                <ul className="menu">
                    {this.hasLoaded &&
                        this.populateData()
                    }
                </ul>
            </nav>
            </>
        )
    }
}


export default Nav;