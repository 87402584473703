export default class DomainStore {

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    DOMAIN = '//billygee.gr';

    getDefaultMetaData(){
        return ({ content: '<meta name="robots" content="index, follow" /> <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" /><meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" /><meta property="og:locale" content="en_US" /><meta property="og:type" content="website" /> <meta property="og:title" content="BillyGee | Skater | Muralist from Athens Greece" /><meta property="og:site_name" content="BillyGee" /> <meta property="article:modified_time" content="2017-04-10T20:18:00+00:00" /><meta name="twitter:card" content="summary" />'});
    }
}