import React from 'react';
import {observer} from 'mobx-react';
import BaseComponent from './baseComponent';

@observer
class Page extends BaseComponent {

    constructor(props, context){
        super(props, context);
        const domain = this.context.DomainStore.DOMAIN;
        this.endpoint = `${domain}/wp-json/wp/v2/pages/?slug=${this.props.slug}&_embed=1`;
    }

    /**
     * get the appropriate size for the images
     * @param {*} source 
     */
    getPostImage(source){
        return (!source.large) 
            ? source.full?.source_url 
            : source.large?.source_url;
    }

    render(){
        const createMarkup = () => ({__html: this.content.content.rendered});
        return (
            <>
            {this.hasLoaded &&
                <article className="flex flex-wrap single single--page">
                    
                    {/* <header className="text-center single__header">
                        {this.content._embedded["wp:featuredmedia"][0]?.media_details?.sizes &&
                        <figure className="flex flex-abs-center single__image">
                            <img src={this.getPostImage(this.content._embedded["wp:featuredmedia"][0].media_details.sizes)} 
                                alt={this.content.title}
                            />
                        </figure>
                        }
                    </header> */}
                    <div className="single__content">
                        <h1 className="text-center single__title">
                            {this.content.title.rendered}
                        </h1>
                        <main className="single__wrapper" dangerouslySetInnerHTML={createMarkup()} />
                    </div>
                </article>
            }
            </>
        )
    }
}

export default Page;