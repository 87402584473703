import UIStore from './ui';
//import InitStore from './init';
import DomainStore from './domain';

class RootStore {
    
    constructor() {
        // Added for future proofing the project
        //this.InitStore = new InitStore(this);
        this.DomainStore = new DomainStore(this);
        this.UIStore = new UIStore(this);
    }

}

const rootStore = new RootStore();
export default rootStore;