import React from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import BaseComponent from './baseComponent';
import { fetchData } from '../utils/common';
import {runInAction} from 'mobx';

@observer
class Home extends BaseComponent {

    constructor(props,context){
        super(props, context);
        this.endpoint = 'https://billygee.gr/wp-json/wp/v2/categories/';
    }

    /**
     * Fetch the remote data
     * @param {*} endpoint 
     */
    async getContent(endpoint){
        let data = null;
        data = await fetchData(endpoint);

        if(data){
            this.content = data;
            runInAction(() => this.hasLoaded = true );
            this.contructHeadData();
        }  
    }

    populateData(){
        if(this.hasLoaded && this.content){
            const blocks = this.content.filter(item => item.slug !== 'uncategorized');

            return blocks.map(item => (
                <div key={item.id} className={`col sm-50 md-33 text-center home-section category category--${item.slug}`}>
                    <Link to={`/category/${item.slug}`}>
                        <div className={`category__wrap category__wrap--${item.slug}`}></div>
                        <h2 className="text-hidden category__name">
                            {item.name}
                        </h2>
                    </Link>
                </div>
            ));
        }
    }

    render(){
        return (
            <main className="page home">
                <section className="row row--md flex flex-wrap flex-jcenter categories">
                    <h1 className="text-hidden col text-center black">Contemporary Artist &amp; Muralist / Skateboarder Athens GR.</h1>
                    {this.hasLoaded &&
                        this.populateData()
                    }
                </section>
            </main>
        );
    }
}

export default Home;